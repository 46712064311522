#login {
    display: flex;
    flex-direction: column;
    width: 400px;
    max-width: 500px;
    min-height: 550px;
    margin: 2rem 0 3rem 0;
    overflow: hidden;
    justify-content: center;
    align-items: center;
    background: var(--accent-clr-5);
    background-clip: padding-box;
    border-radius: 40px;
    box-shadow: 8px 8px 20px 5px var(--bg-clr-1);
}


/* login background */
.login-parallax {
    display: flex;
    width: 400px;
    height: 550px;
    /* max-height: 350px; */
    z-index: -1;
    position: absolute;
    justify-content: space-between;
    overflow: hidden;
    border-radius: 40px;
}
.login-parallax .dot {
    aspect-ratio: 1;
    border-radius: 100%;
    filter: blur(50px);
    background: linear-gradient(115deg, var(--txt-clr-3) 20%, rgba(255,169,146,1) 80%);
    
}
.login-parallax .dot:nth-child(1) {
    width: 340px;
    place-self: flex-end;
    transform: translate(20%, 30%);
}
.login-parallax .dot:nth-child(2) {
    width: 330px;
    place-self: flex-start;
    transform: translate(-25%, -35%);
}

/* login form */
.login-container {
    display: flex;
    flex-direction: column;
    padding: 1.5rem;
    width: 100%;
    min-height: 550px;
    /* gap: 2rem; */
    align-items: center;
    justify-content: space-between;
}
.login-heading-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: .3rem;
}
.login-heading-line {
    display: inline-flex;
    width: 40%;
    height: 5px;
    border-radius: 100px;
    margin-left: 2%;
    background: var(--accent-clr-6);
    opacity: 1;
    transition: all 1s ease-in;
}

.login-form {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 2rem;
}
.label-container {
    display: inline-flex;
    display: flex;
    flex-direction: column;
    gap: .5rem;
    filter: brightness(1) grayscale(1);
    transition: filter 150ms ease-out;
}
.label-container:hover,
.label-container:focus-within {
    filter: brightness(0.9) grayscale(0);
    transition: filter 150ms ease-in;
}
.label-container .input-container {
    display: inline-flex;
    border: 2.5px solid var(--txt-clr-3);
    border-radius: 40px;
    height: 50px;
    padding: 10px;
    outline: none !important;
}
.label-container .input-container .input {
    border: none !important;
    outline: none !important;
    background: none !important;
    width: 100%;
    color: var(--txt-clr-3);
    font-family: var(--txt-4);
    letter-spacing: .5px;
    font-size: 1.1rem;
    transition: all 300ms linear;
}
.label-container .input-container .input::placeholder {
    color: var(--txt-clr-3);
    opacity: .5
}
.label-container.password-container .input-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    gap: 10px;
}
.label-container.label-container.password-container .input-container .password-toggle-container {
    display: inline-flex;
    flex-direction: column;
    width: max-content;
    align-items: center;
    justify-content: center;
    filter: brightness(.85);
    transition: filter 250ms ease-out;
}
.label-container.label-container.password-container .input-container .password-toggle-container:hover {
    display: inline-flex;
    flex-direction: column;
    width: fit-content;
    align-items: center;
    justify-content: center;
    filter: brightness(1);
    transition: filter 250ms ease-out;
}
.eye {
    display: inline-flex;
    position: absolute;
    aspect-ratio: 1;
    width: 1.6rem;
    pointer-events: none;
    color: var(--accent-clr-4);
}
.label-container.password-container .input-container #password-toggle {
    display: inline-flex;
    opacity: 0;
    aspect-ratio: 1;
    width: 1.8rem;
    cursor: pointer;
}

.label-container .label {
    display: inline-flex;
    font-size: 1.4rem;
    font-family: var(--txt-1);
    color: var(--txt-clr-3);
    margin-left: 1%;
    transition: all 300ms linear;
}
.login-btn-container {
    display: inline-flex;
    margin-top: 2rem;
}
.login-btn {
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 40px;
    border: 3.5px solid var(--txt-clr-3);
    border-radius: 50px;
    transition: all 350ms linear;
    max-width: max-content;
    opacity: .85;
}
.login-btn:disabled {
    background-color: var(--accent-clr-1);
    border-color: var(--accent-clr-1);
    opacity: .5;
    cursor: not-allowed;
}
.login-btn:disabled span {
    color: var(--bg-clr-1);
}
.login-btn span {
    font-size: 1.3rem;
    font-family: var(--txt-1);
    letter-spacing: .5px;
    font-weight: 700;
    color: var(--txt-clr-3);
    filter: saturate(2.5);
    transition: all 350ms linear;
}

.login-btn:not(:disabled):hover {
    background: var(--txt-clr-3);
    transition: all 350ms linear;
}
.login-btn:not(:disabled):hover span {
    color: var(--bg-clr-1);
    transition: all 350ms linear;
}

/* error */
#error {
    display: flex;
    width: 100%;
    background-color: var(--accent-clr-6);
    font-family: var(--txt-1);
    font-size: 1rem;
    justify-content: center;
    align-items: center;
    border-radius: .5ex;
    opacity: 1;
    animation: fadeOut 500ms forwards;
    animation-delay: 2s;
}

@keyframes fadeOut {
    to {
        opacity: 0;
    }
}