#particles {
    width: 100%;
    height: 100%;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
}

#particles i {
    position: absolute;
    display: block;
    width: 8px;
    border-radius: 100%;
    aspect-ratio: 1;
    background-color: var(--accent-clr-4);
    filter: brightness(.9) opacity(.4);
    transform: translateX(var(--tx)) translateY(var(--ty));
    transition: transform 10s linear;
    opacity: 0;
    animation: explode 200ms linear forwards;
    animation-delay: 10s;
}

@keyframes explode {
    to {
        opacity: .8;
        box-shadow: 0 0 25px 3.5px var(--accent-clr-4);
    }
}