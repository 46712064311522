/* fonts */
@import url('https://fonts.googleapis.com/css2?family=Comfortaa&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Cherry+Bomb+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Libre+Caslon+Text&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lora&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');

/* base */
:root {
    --bg-clr-1: #000000e4;
    --bg-clr-2: #41414198;
    --bg-clr-3: #655e56;
    --nav-clr: #ffffff33;
    --txt-clr-1: #f0f0f0;
    --txt-clr-2: #f1efef;
    --txt-clr-3: #dff1f6e7;
    --accent-clr-1: #bcbbbb;
    --accent-clr-2: #1a191972;
    --accent-clr-3: #838383c5;
    --accent-clr-4: #ffffffd5;
    --accent-clr-5: #2f2e2e72;
    --accent-clr-6: #ff9794d5;
    --accent-clr-7: #f1d0af;
    --btn-clr-1: #75ed75;
    --btn-clr-2: #ef6363;
    --txt-1: Comfortaa;
    --txt-2: Lora;
    --txt-3: "Libre Caslon Text";
    --txt-4: Montserrat;
    --carousel-padding: 4rem;
}
body {
    background: var(--bg-clr-1);
    font-family: var(--txt-2);
    margin: 0;
    overflow-x: hidden;
    scroll-behavior: smooth;
}
#root {
    min-height: 100vh;
}
#parallax {
    background: linear-gradient(-.64turn, var(--accent-clr-2), var(--accent-clr-5), var(--accent-clr-3));
    background-size: 300vw;
    animation: radiance 30s linear infinite;
    position: fixed;
    width: 100vw;
    min-height: 100%;
    z-index: -1;
}
img:not(.img-holder img):not(.preview-minimal-icon img) {
    pointer-events: none !important;
}
a {
    text-decoration: none !important;
    cursor: pointer;
}
*, *::after, *::before {
    box-sizing: border-box;
}
button {
    outline: none;
    margin: none;
    padding: none;
    border: none;
}
::selection {
    background-color: var(--accent-clr-3);
}
[select="false"], [select="false"] * {
    user-select: none !important;
    -webkit-user-drag: none !important;
}
[data-draggable="true"] {
    -webkit-user-drag: element !important;
}
[animate='false'], [animate='false'] * {
    animation: none !important;
}

.\@container {
    container-type: inline-size;
}

::-webkit-scrollbar {
    width: 3.5px;
}
::-webkit-scrollbar-track {
    background-color: transparent;
}
::-webkit-scrollbar-thumb {
    background-color: var(--accent-clr-3);
    border-radius: 1ex;
}
::-webkit-scrollbar-thumb:active,
::-webkit-scrollbar-thumb:hover {
    background-color: var(--accent-clr-1);
}

/* main */
#main {
    margin-top: 4%;
    padding: 0 4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
section {
    width: 100%;
    margin-top: 1.5rem;
    margin-bottom: 2rem;
    display: flex;
    flex-direction: column;
    animation: fadeIn 1.2s reverse;
}
section.visible .heading-container {
    animation: popDown 1.2s linear forwards;
}
.heading-container {
    display: flex;
    align-items: center;
    padding: 0 1rem;
    width: 100%;
    justify-content: var(--heading-align);
    background: linear-gradient(
        var(--gradient-angle, 45deg),
        var(--accent-clr-3) 1%,
        transparent
    );
    mask: linear-gradient(
        var(--masking-angle, 180deg),
        transparent,
        white 80%
    );
    -webkit-mask: linear-gradient(
        var(--masking-angle, 180deg),
        transparent,
        white 80%
    );
    pointer-events: none;
}
.heading-container:not([animate="false"]) {
    opacity: 0;
    margin-top: -4rem;
}
h2.heading {
    display: inline-flex;
    color: var(--txt-clr-2);
    font-family: var(--txt-4);
    text-transform: uppercase;
    font-weight: bolder;
    font-size: 10vmin;
    letter-spacing: 3px;
    margin: 0;
}

/* maintenance */
.maintenance {
    color: var(--txt-clr-3);
    font-family: var(--txt-1);
    font-size: 4vmax;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    text-align: center;
    flex-flow: row wrap;
}
.maintenance-icon {
    font-size: 4vmax;
    opacity: .5;
    animation: beat 800ms infinite alternate-reverse;
}
.err-text {
    font-size: 1.5rem;
    color: var(--txt-clr-2);
    font-family: var(--txt-4);
    font-weight: 600;
    letter-spacing: 0.5px;
}

/* animations */
@keyframes radiance {
    0%, 100% {
        background-position: 0%;
    }
    25% {
        background-position: 50%
    }
    50% {
        background-position: 100%;
    }
}

@keyframes border-anim {
    0% {
        background-image: linear-gradient(90deg, transparent 39%, var(--txt-clr-2) 40%, var(--txt-clr-2) 60%, transparent 61%);
    }
    10% {
        background-image: linear-gradient(90deg, transparent 34%, var(--txt-clr-2) 35%, var(--txt-clr-2) 65%, transparent 66%);
    }
    20% {
        background-image: linear-gradient(90deg, transparent 29%, var(--txt-clr-2) 30%, var(--txt-clr-2) 70%, transparent 71%);
    }
    30% {
        background-image: linear-gradient(90deg, transparent 24%, var(--txt-clr-2) 25%, var(--txt-clr-2) 75%, transparent 76%);
    }
    40% {
        background-image: linear-gradient(90deg, transparent 19%, var(--txt-clr-2) 20%, var(--txt-clr-2) 80%, transparent 81%);
    }
    50% {
        background-image: linear-gradient(90deg, transparent 14%, var(--txt-clr-2) 15%, var(--txt-clr-2) 85%, transparent 86%);
    }
    60% {
        background-image: linear-gradient(90deg, transparent 9%, var(--txt-clr-2) 10%, var(--txt-clr-2) 90%, transparent 91%);
    }
    70% {
        background-image: linear-gradient(90deg, transparent 14%, var(--txt-clr-2) 15%, var(--txt-clr-2) 85%, transparent 86%);
    }
    80% {
        background-image: linear-gradient(90deg, transparent 19%, var(--txt-clr-2) 20%, var(--txt-clr-2) 80%, transparent 81%);
    }
    90% {
        background-image: linear-gradient(90deg, transparent 24%, var(--txt-clr-2) 25%, var(--txt-clr-2) 75%, transparent 76%);
    }
    100% {
        background-image: linear-gradient(90deg, transparent 29%, var(--txt-clr-2) 30%, var(--txt-clr-2) 70%, transparent 71%);
    }
}

@keyframes autoscroll {
    to {
        transform: translateX(calc(-50% - .5rem));
    }
}

@keyframes fadeIn {
    to {
        width: 100%;
        opacity: 80%;
    }
}
@keyframes popDown {
    to {
        transform: translate(0);
        opacity: 80%;
    }
}

@keyframes beat {
    to {
        opacity: 1;
    }
}

@keyframes shift {
    0% {
        right: 0;
        width: 0;
    }
    50% {
        right: 0;
        width: 100%;
    }
    100% {
        right: 100%;
        width: 0;
    }
}

/* media queries */
@media all and (max-width: 575px) {
    #main {
        padding: 0 1rem;
    }
}
@media all and (max-width: 767px) and (min-width: 576px) {
    #main {
        padding: 0 2rem;
    }
}