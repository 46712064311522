.carousel {
    width: 100%;
    max-width: 100%;
    max-height: 500px;
    display: flex;
    flex-direction: row;
    overflow-x: hidden;
    justify-content: space-between;
    align-items: center;
    padding: 2rem 0;
    mask: linear-gradient(
        90deg,
        transparent,
        white 20%,
        white 80%,
        transparent
    );
    -webkit-mask: linear-gradient(
        90deg,
        transparent,
        white 20%,
        white 80%,
        transparent
    );
}
.carousel-wrapper:hover, .carousel-wrapper:focus, .carousel-wrapper:focus-within {
    animation-play-state: paused;
}
.carousel-wrapper {
    --carousel-const: -50%;
    display: flex;
    flex-direction: row;
    gap: 1rem;
    width: max-content;
    justify-content: space-between;
    flex-shrink: 0;
}
.carousel-wrapper {
    animation: autoscroll 20s linear infinite;
    animation-direction: var(--direction);
    overflow-y: visible;
}
.carousel-box {
    --default-img-size: 330px;
    display: inline-flex;
    flex-grow: 0;
    flex-shrink: 0;
    max-width: var(--default-img-size);
    aspect-ratio: 1;
    background-color: #655e5693;
    backdrop-filter: blur(2px);
    background-clip: content-box;
    overflow: hidden;
    border-radius: 10px;
    box-shadow: 0 10px 10px 10px var(--accent-clr-2);
    transition: transform 100ms linear;
    animation: none;
}