#footer {
    top: 100%;
    position: sticky;
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: max-content;
    overflow: hidden;
    background: #0000007d;
    box-shadow: 0 2px 10px 5px var(--bg-clr-1);
    align-items: center;
    justify-content: center;
    gap: 1.5rem;
    z-index: 90;
    margin-top: 5%;
}

/* footer background */
.footer-parallax {
    display: inline-flex;
    z-index: -1;
    /* width: 100%; */
    justify-content: space-between;
    align-items: center;
    overflow: hidden;
    position: absolute;
    filter: opacity(.5);
}
.footer-parallax .dot {
    aspect-ratio: 1;
    border-radius: 100%;
    filter: blur(50px);
    background: linear-gradient(115deg, var(--txt-clr-3) 20%, rgba(255,169,146,1) 80%);
}
.footer-parallax .dot:nth-child(1) {
    --masking-angle: 115deg;
    width: 330px;
    place-self: flex-start;
    transform: translate(-40%, -35%);
}
.footer-parallax .dot:nth-child(2) {
    width: 340px;
    place-self: flex-end;
    transform: translate(20%, 30%);
}

/* copyright and policies */
.bottom-bar {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: var(--txt-2);
    padding: 0 0 10px 0;
    gap: 2px;
}
.copyright-bar {
    display: inline-flex;
    letter-spacing: .5px;
    color: var(--txt-clr-1);
    opacity: 0.7;
}
.policies-bar {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
}
.policies-bar .policy-link {
    font-size: 13.5px;
    color: var(--accent-clr-1);
    opacity: .8;
}
.policies-bar .policy-link:hover {
    text-decoration: underline !important;
    text-underline-offset: 4px;
}
.policies-bar .separator {
    aspect-ratio: 1;
    width: 4px;
    border-radius: 100%;
    background-color: var(--accent-clr-1);
    opacity: .8;
}

/* footer content */
.footer-content-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    flex-direction: column;
}
.footer-content {
    display: flex;
    flex-flow: row;
    width: 100%;
    padding: 20px 3rem 0 3rem;
    gap: 6rem;
}
.footer-content .column-1 {
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.footer-content .column-1 .logo {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    aspect-ratio: 1;
    width: 200px;
    overflow: hidden;
}
.footer-content .column-1 .logo img {
    width: 100%;
    object-fit: cover;
}
.footer-content .column-2 .contact-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 20px;
    border: 3.5px solid var(--txt-clr-3);
    border-radius: 50px;
    transition: all 350ms linear;
    max-width: max-content;
    opacity: .85;
}
.footer-content .column-2 .contact-btn span {
    font-family: var(--txt-1);
    letter-spacing: .5px;
    font-weight: 700;
    color: var(--txt-clr-3);
    filter: saturate(2.5);
    transition: all 350ms linear;
}
.footer-content .column-2 .contact-btn:hover {
    background: var(--txt-clr-3);
    transition: all 350ms linear;
}
.footer-content .column-2 .contact-btn:hover span {
    color: var(--bg-clr-1);
    transition: all 350ms linear;
}

.footer-content .column-2 {
    width: 100%;
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 20px;
}
.footer-content .column-2 .socials-bar {
    display: flex;
    flex-direction: column;
    gap: 1.3rem;
    width: 100%;
}
.footer-content .column-2 .socials-bar .socials-heading-container {
    display: flex;
    flex-direction: column;
    width: fit-content;
}
.footer-content .column-2 .socials-bar .socials-heading-container .socials-heading {
    display: inline-flex;
    color: var(--txt-clr-1);
    font-family: var(--txt-4);
    font-weight: bolder;
    letter-spacing: .5px;
    margin-bottom: .65rem;
}
.footer-content .column-2 .socials-bar .socials-heading-container .socials-heading-line {
    display: inline-flex;
    width: 30%;
    height: 3px;
    background-color: var(--accent-clr-6);
}

.footer-content .column-2 .socials-bar .socials {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    column-gap: 3.5rem;
    row-gap: 2rem;
    width: 100%;
}
.footer-content .column-2 .socials-bar .socials .social {
    display: inline-flex;
    aspect-ratio: 1;
    min-width: 2.2rem;
    max-width: 2.2rem;
    flex-shrink: 0;
}
.footer-content .column-2 .socials-bar .socials .social img {
    width: 100%;
    object-fit: contain;
    filter: contrast(0.001);
    transition: filter 250ms ease-in;
}
.footer-content .column-2 .socials-bar .socials .social:hover img {
    filter: contrast(1);
    transition: filter 250ms ease-in;
}

/* media queries */
@media all and (max-width: 575px) {
    #footer {
        margin-top: 10%;
    }
    .footer-content {
        flex-direction: column;
        padding: 1rem 2rem;
        gap: 20px;
    }
    .footer-content .column-1 .logo {
        width: 190px;
    }
    .footer-content .column-2 {
        align-items: center;
        width: 100%;
        gap: 2rem;
        width: 100%;
    }
    .footer-content .column-2 .socials-bar {
        width: 100%;
    }
}

@media all and (max-width: 767px) and (min-width: 576px) {
    #footer {
        margin-top: 10%;
    }
    .footer-content {
        flex-direction: row;
        padding: 1rem 2rem;
        gap: 20px;
    }
    .footer-content .column-1 .logo {
        width: 190px;
    }
    .footer-content .column-2 {
        width: 100%;
        gap: 2rem;
        width: 100%;
    }
    .footer-content .column-2 .socials-bar {
        width: 100%;
    }
}
@media all and (max-width: 991px) and (min-width: 768px) {
    #footer {
        margin-top: 25%;
    }
}