.img-holder {
    display: grid;
    place-items: center;
    aspect-ratio: 1;
    background-color: #ffffff65;
    border-radius: 10px;
    overflow: hidden;
    width: 100%;
}
.order {
    z-index: 8;
    position: absolute;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    color: var(--txt-clr-1);
    filter: opacity(.8);
    font-family: var(--txt-4);
    font-weight: 600;
    font-size: 4vmin;
    letter-spacing: 2px;
}
.temp-img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    z-index: 8;
    vertical-align: middle;
    cursor: move;
}
.temp-img.empty {
    display: none;
}

.remove-btn {
    outline: none;
    border: none;
    border-radius: 10px;
    aspect-ratio: 1;
    display: grid;
    place-content: center;
    place-self: flex-start flex-end;
    position: absolute;
    justify-self: flex-end;
    z-index: 9;
    margin: 5px 5px 0 0;
    font-size: 3.2vmin;
    background-color: var(--btn-clr-2);
    color: var(--accent-clr-4);
    backdrop-filter: blur(4px);
    opacity: 0;
    filter: brightness(1);
    transition: all 100ms linear;
}
.img-holder:hover .remove-btn {
    opacity: 1;
    transition: all 100ms linear;
}
.remove-btn:hover, .remove-btn:active {
    filter: brightness(.9);
    transform: all 100ms linear;
}

.dragging[data-draggable="true"] {
    opacity: 0;
}