.project-card {
    display: flex;
    aspect-ratio: 4 / 3 !important;
    min-width: 330px;
}

.project-card .banner {
    display: inline-flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    position: absolute;
    justify-content: flex-end;
    align-items: flex-start;
    background: none;
    transition: all 500ms linear;
}

.project-card .banner .labels {
    display: inline-flex;
    flex-direction: column;
    padding: 10px;
    gap: 1em;
    background: var(--accent-clr-5);
    backdrop-filter: blur(5px) brightness(.9) saturate(1.9);
    border-top-right-radius: 20px;
    box-sizing: content-box;
    transition: gap 200ms ease-in;
}
.project-card .banner .label {
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 3px;
}
.project-card .banner .label .text {
    font-size: .85rem;
    color: var(--accent-clr-4);
    filter: brightness(.9) opacity(.9);
    letter-spacing: 1.5px;
    font-family: var(--txt-1);
    font-weight: 700;
}
.project-card .banner .label .icon {
    aspect-ratio: 1;
    width: 30px;
    justify-content: center;
    align-items: center;
}
.project-card .banner .label .icon img {
    aspect-ratio: 1;
    object-fit: cover;
    width: 100%;
    color: var(--accent-clr-4)
}

.project-card .banner .project-title-container {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    font-size: 1.55rem;
    letter-spacing: 1.5px;
    font-family: var(--txt-3);
}
.project-title-container .project-title {
    display: inline-flex;
    gap: 10px;
    text-decoration: none;
    color: var(--accent-clr-4);
}
.project-title span:nth-child(1), .project-title span:nth-child(3) {
    text-decoration: underline;
    text-underline-offset: 4px;
}
.project-title span:nth-child(2) {
    opacity: 0.8;
    font-size: 1.7rem;
    font-style: italic;
}

.repo-img {
    display: inline-flex;
    object-fit: cover;
    width: 100%;
}

.project-card:hover .banner {
    background: var(--accent-clr-5);
    backdrop-filter: blur(7px) saturate(1.9);
    box-sizing: content-box;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    transition: all 500ms linear;
}
.project-card:hover .labels {
    background: transparent !important;
    flex-direction: row;
    backdrop-filter: unset;
    justify-content: center;
    align-items: center;
    gap: 4.5rem;
    margin-bottom: -30%;
    transition: gap 300ms ease-in;
}
.project-card:hover .project-title-container {
    opacity: 1;
}