#gallery {
    display: flex;
    flex-flow: column;
    width: 100%;
    justify-content: center;
    align-items: center;
    margin-bottom: 6%;
}

.date {
    font-size: xx-large;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    padding: 1rem;
    color: var(--txt-clr-1);
    font-family: Montserrat;
    font-weight: 600;
    letter-spacing: 2px;
    position: sticky;
    top: 6rem;
    left: 4rem;
    margin: 0;
    z-index: 10;
    border-top-left-radius: 20px;
    backdrop-filter: blur(10px);
    background: linear-gradient(
        90deg,
        var(--accent-clr-3) 1%,
        transparent
    );
    mask: linear-gradient(
        0deg,
        transparent,
        white 80%
    );
    -webkit-mask: linear-gradient(
        0deg,
        transparent,
        white 80%
    );
}
.date-container {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    gap: 6px;
    position: relative;
}
.date span:nth-child(2), .date span:nth-child(4) {
    opacity: .6;
}
.gallery-sections {
    display: flex;
    flex-flow: column;
    width: 100%;
    gap: 4rem;
}

.gallery-section {
    display: flex;
    flex-flow: column;
    width: 100%;
}
.img-wrapper {
    display: flex;
    gap: 8px;
    flex-shrink: 0;
    flex-wrap: wrap;
    border-radius: 5px;
    padding: 0 .5rem;
}

.gallery-img {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 15vmax;
    height: 15vmax;
    overflow: hidden;
    border-radius: 5px;
    transition: all 100ms linear;
}
.gallery-section .skeleton-loading {
    display: inline-flex;
    width: 15vmax;
    aspect-ratio: 1;
    position: absolute;
    border-radius: 5px;
    animation: skeletonLoading 600ms linear infinite alternate;
}
.gallery-img:hover {
    transform: scale(1.05);
    box-shadow: 0 10px 10px 10px var(--accent-clr-2);
    transition: all 100ms linear;
}
.gallery-img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

@keyframes skeletonLoading {
    0% {
        background-color: hsl(0, 0%, 20%);
    }
    100% {
        background-color: hsl(0, 0%, 35%);
    }
}

/* media queries */
@media all and (max-width: 575px) {
    #gallery {
        margin-top: 6%;
    }
    .img-wrapper {
        gap: 5px;
    }
}

@media all and (max-width: 767px) and (min-width: 576px) {
    #gallery {
        margin-top: 6%;
    }
    .img-wrapper {
        gap: 5px;
    }
}