.store-item {
    background: var(--accent-clr-3);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: fit-content;
    overflow: hidden;
    box-shadow: 10px 10px 20px 2px var(--bg-clr-1);
    transform: scale(1);
    transition: transform 200ms ease;
    /* opacity: 0;
    animation: itemReveal linear forwards;
    animation-timeline: view();
    animation-range: cover; */
}
.store-item * {
    outline: none !important;
}
.store-item:hover,
.store-item:active,
.store-item:focus-within {
    transform: scale(1.02);
    transition: transform 200ms ease;
}

.store-item-img {
    display: inline-flex;
    width: 100%;
    aspect-ratio: 1;
    overflow: hidden;
}
.store-item-img img {
    object-fit: contain;
    width: 100%;
}
.store-item-details {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-between;
    gap: 10px;
    padding: 1rem;
    text-align: right !important;
    background-color: var(--accent-clr-2);
    height: fit-content;
    width: 100%;
}
.store-item-details .store-item-details-heading {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    flex-direction: column;
}
.store-item-details .store-item-details-heading .store-item-details-name {
    display: inline-flex;
    font-size: 1.2rem;
    font-family: var(--txt-1);
    color: var(--accent-clr-4);
}
.store-item-details .store-item-details-heading .store-item-details-manufacturer {
    display: inline-flex;
    font-size: .9rem;
    font-family: var(--txt-4);
    color: var(--accent-clr-1);
}

.store-item-details .store-item-details-info {
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: flex-end;
    justify-content: flex-end;
    width: 100%;
}
.store-item-details .store-item-details-pricing-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    justify-content: space-between;
}
.store-item-details .store-item-details-price {
    display: inline-flex;
    font-family: var(--txt-4);
    font-size: 1.5rem;
    font-weight: 700;
    letter-spacing: .5px;
    color: var(--txt-clr-3);
}
.store-item-details .store-item-cart-btn {
    display: inline-flex;
    aspect-ratio: 1;
    padding: 8px;
    border-radius: 100%;
    background: var(--accent-clr-7);
    transition: filter 300ms ease;
}
.store-item-details .store-item-cart-btn span {
    color: var(--accent-clr-2);
    font-size: 1.4rem;
}
.store-item-details .store-item-cart-btn:hover {
    filter: brightness(.8);
    transition: filter 300ms ease;
}

.store-item-details .store-item-cart-btn:active {
    filter: brightness(1.1);
    transition: filter 300ms linear;
}

.store-item-details .store-item-details-tags {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-end;
    align-items: flex-start;
    gap: 10px;
}
.store-item-details .store-item-details-tags .store-item-tag {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    gap: 2px;
    border-radius: 20px;
    background-color: var(--accent-clr-2);
    padding: 3px 7px 3px 3px;
    transition: background-color 250ms linear;
}
.store-item-details .store-item-details-tags .store-item-tag .hash {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-size: 1.25rem;
    vertical-align: middle;
    line-height: 1;
    color: var(--accent-clr-1);
    opacity: .7;
}
.store-item-details .store-item-details-tags .store-item-tag .tag-text {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-size: .9rem;
    font-family: var(--txt-1);
    vertical-align: middle;
    line-height: 1;
    color: var(--accent-clr-1);
    text-transform: lowercase;
}
.store-item-details .store-item-details-tags .store-item-tag:hover,
.store-item-details .store-item-details-tags .store-item-tag:active {
    background-color: var(--accent-clr-3);
    transition: background-color 250ms linear;
}

/* animations */
/* @keyframes itemReveal {
    to {
        opacity: 1;
    }
} */