.carousel-box img {
    width: 100%;
    object-fit: cover;
}


.carousel-box.image-card {
    --default-img-size: 330px;
    display: inline-flex;
    flex-grow: 0;
    flex-shrink: 0;
    max-width: var(--default-img-size);
    aspect-ratio: 1;
    width: var(--default-img-size);
    background-color: #655e5693;
    backdrop-filter: blur(2px);
    background-clip: content-box;
    overflow: hidden;
    border-radius: 10px;
    box-shadow: 0 10px 10px 10px var(--accent-clr-2);
    transition: transform 100ms linear;
}

.carousel .carousel-box.image-card:hover, .carousel.active .carousel-box.image-card:hover {
    transform: scale(1.1);
    transition: transform 250ms linear;
    z-index: 999;
}